/**
 * NOTE:
 * These hooks are used when the pages to be protected do not include pages that require authentication.
 */

import { usePathname } from "next/navigation"

import { BENDAHARA_PATHS, GLOBAL_RESTRICTED_PATHS } from "../config"
import { matchesPath } from "../helpers"
import { USER_ROLE } from "@/types/type"

type TUseRestrictedPage = {
  role?: string
}

const useRestrictedPage = ({ role }: TUseRestrictedPage) => {
  const pathName = usePathname() as string
  const allowedBendaharaPath = BENDAHARA_PATHS.concat(GLOBAL_RESTRICTED_PATHS)
  const isBendaharaRole =
    role === USER_ROLE.BENDAHARA || role === USER_ROLE.BENDAHARA_UD

  if (
    isBendaharaRole &&
    !matchesPath(allowedBendaharaPath, pathName) &&
    typeof window !== "undefined"
  ) {
    window?.location?.replace("/bendahara")
  }
}

export default useRestrictedPage
