import { withScope, captureException } from "@sentry/nextjs"
import { QueryCache, MutationCache } from "@tanstack/react-query"

const REACT_QUERY_BASE_CONFIG = {
  queryCache: new QueryCache({
    onError: (err, query) => {
      withScope((scope) => {
        scope.setContext("query", { queryHash: query.queryHash })
        scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, "0")])
        captureException(err)
      })
    },
  }),
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      withScope((scope) => {
        scope.setContext("mutation", {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        })
        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            Array.from(mutation.options.mutationKey) as string[]
          )
        }
        captureException(err)
      })
    },
  }),
}

export default REACT_QUERY_BASE_CONFIG
