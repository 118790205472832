import { type Session } from "next-auth"
import {
  APP_TYPE,
  FEEDS_IDENTIFIER,
  NotificationProvider,
  usePersonaNotification,
} from "notifications"
import { useEffect, type PropsWithChildren } from "react"

import { NOVU_CONFIGURATION } from "@/config/client"
import useInstitutionNotifications from "@/hooks/notification/useInstitution"
import useSubscriberHash from "@/hooks/notification/useSubscriberHash"

interface INotificationProviderProps extends PropsWithChildren {
  session: Session | null
}

const NotificationProviders = (props: INotificationProviderProps) => {
  const { subscriberHash, subscriberId } = useSubscriberHash()
  const { setUser } = usePersonaNotification()
  const satkerData = useInstitutionNotifications(
    props.session?.user?.institutionId || ""
  )

  useEffect(() => {
    if (satkerData) {
      setUser({
        personaId: props.session?.user?.personaId || "",
        institutionId: props.session?.user?.institutionId || "",
        satker: satkerData.namaSatker,
        role: props.session?.user?.role || "",
      })
    }
  }, [satkerData])

  return (
    <NotificationProvider
      novuConfiguration={NOVU_CONFIGURATION}
      subscriberId={subscriberId}
      subscriberHash={subscriberHash}
      appType={APP_TYPE.buyer}
      feeds={FEEDS_IDENTIFIER.govmart}
    >
      {props.children}
    </NotificationProvider>
  )
}

export default NotificationProviders
