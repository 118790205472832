export const notificationLabel = {
  ERROR_UNWHITELIST: "Anda belum bisa mengakses platfom pengadaan nasional",
  ERROR_GET_USER: "Anda belum bisa mengakses platfom pengadaan nasional",
  ERROR_GET_INSTITUTION_BUYER:
    "Anda belum bisa mengakses platfom pengadaan nasional",
  ERROR_GET_BUYER: "Anda belum bisa mengakses platfom pengadaan nasional",
  ERROR_GET_SELLER: "Anda belum bisa mengakses platfom pengadaan nasional",
  ERROR_GET_INTERNAL: "Anda belum bisa mengakses platfom pengadaan nasional",
  ERROR_UNKNOWN: "Terjadi kesalahan pada login. Silakan login kembali",
  ERROR_TOKEN_NOT_FOUND: "Terjadi kesalahan pada login. Silakan login kembali",
  ERROR_ACCESS_TOKEN:
    "Sesi sudah habis. Silakan login kembali untuk melanjutkan",
  ERROR_ACCESS_ROLE: "Akun anda tidak memiliki akses",
  SUCCESS_LOGIN:
    "Selamat datang! Anda telah berhasil masuk ke akun Katalog Elektronik.",
  ERROR_TOKEN_INVALID: "",
  ERROR_TOKEN_REFRESH: "",
  ERROR_SESSION_TOKEN_EXPIRED: "",
}
