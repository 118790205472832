export type GenericExtractor<
  T extends { __typename?: string } | undefined | null
> = Exclude<T, { __typename?: "Error" | "GenericError" }>

export type NullishExtractor<T> = Exclude<T, undefined | null>

export type TUploadFileRequest = {
  signedUrl: string
  formData: File
  index: number
}

// eslint-disable-next-line no-shadow
export enum USER_ROLE {
  BUYER = "BUYER",
  BENDAHARA = "BENDAHARA",
  PP = "PP",
  PPK = "PPK",
  BENDAHARA_UD = "BENDAHARA_UD",
}

export type TJwtOrderToken = {
  exp: number
  payment_id: string
  email: string
}

export type TPageProps<N extends object> = {
  params: N
  searchParams: { [key: string]: string | undefined }
}
