"use client"

import { type PropsWithChildren, createContext } from "react"

import { type FeatureFlag } from "@/utils/getFlag"

export const FeatureFlagsContext = createContext<FeatureFlag | null>(null)

const FeatureFlagsProvider = ({
  value,
  children,
}: PropsWithChildren & { value: FeatureFlag | null }) => {
  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

export default FeatureFlagsProvider
