import { useQuery } from "@tanstack/react-query"
import { useState } from "react"

import { GET_INSTITUTION_NOTIFICATIONS } from "./federated/queries"
import { federatedGqlClient } from "@/utils/graphqlClient"

const useInstitutionNotifications = (id: string) => {
  const [isEnable, setIsEnable] = useState(true)

  const { data } = useQuery({
    queryKey: ["institutionNotifications", { id }],
    queryFn: async () => {
      const result = await federatedGqlClient.request(
        GET_INSTITUTION_NOTIFICATIONS,
        { id }
      )

      if (result.getInstitutionByID?.__typename === "GenericError") {
        throw new Error(result.getInstitutionByID.message || "Error")
      }

      setIsEnable(false)
      return result.getInstitutionByID.satker
    },
    // retry if user is logged in
    retry: Boolean(id),
    enabled: Boolean(id) && isEnable,
  })

  return data
}

export default useInstitutionNotifications
