import { deleteCookie } from "cookies-next"

export const tokenSessionStoreName = "__Secure.session-token"
export const tokenSessionStoreName2 = "gtp.user-info"

/**
 * @deprecated use 'clearUserInfo' instead.
 */
export const clearSession = () => {
  deleteCookie(tokenSessionStoreName)
  deleteCookie(tokenSessionStoreName2)
}
