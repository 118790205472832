import { graphql } from "@/federatedGql"

export const GET_SUBSCRIBER_HASH = graphql(`
  query getSubscriberHash {
    subscriberHash {
      subscriberHash
      subscriberId
    }
  }
`)

export const GET_INSTITUTION_NOTIFICATIONS = graphql(`
  query getInstitutionByIDNotifications($id: String!) {
    getInstitutionByID(id: $id) {
      ... on Institution {
        __typename
        satker {
          namaSatker
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)
