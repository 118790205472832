import type { PropsWithChildren } from "react"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

import Snackbar from "@/components/snackbar"

type SnackbarTypes = {
  message: string
  variant: "error" | "success" | "info"
  hasClose: boolean
  customCloseText?: string
  customTimer?: number
  onCustomClose?: () => void
}

interface SnackbarStoreTypes {
  message: string
  variant: "error" | "success" | "info"
  hasClose: boolean
  customCloseText?: string
  customTimer?: number
  onCustomClose?: () => void
  setSnackbar: ({
    message,
    variant,
    hasClose,
    customCloseText,
    customTimer,
    onCustomClose,
  }: SnackbarTypes) => void
  closeSnackbar: () => void
}

export const useSnackbarStore = create<SnackbarStoreTypes>()(
  devtools(
    (set) => ({
      message: "",
      variant: "success",
      hasClose: false,
      setSnackbar: ({
        message,
        variant,
        hasClose,
        customCloseText,
        customTimer = 3000,
        onCustomClose,
      }) =>
        set(() => ({
          message,
          variant,
          hasClose,
          customCloseText,
          customTimer,
          onCustomClose,
        })),
      closeSnackbar: () => set(() => ({ message: "", variant: "success" })),
    }),
    {
      name: "snackbar-store",
    }
  )
)

const SnackbarProvider = (props: PropsWithChildren) => {
  return (
    <>
      <Snackbar />
      {props.children}
    </>
  )
}

export default SnackbarProvider
