"use client"

import * as Sentry from "@sentry/nextjs"
import { type Session } from "next-auth"
import { SessionProvider } from "next-auth/react"
import { useEffect } from "react"
import { clearSession } from "shared-utils/authentication"

import DialogAuth from "./DialogAuth"
import useRestrictedPage from "./hooks/useRestrictedPage"

type TSessionProviderClient = {
  children: React.ReactNode
  session?: Session | null
}

export default function SessionProviderClient({
  children,
  session,
}: TSessionProviderClient) {
  clearSession()

  useRestrictedPage({ role: session?.user?.role })

  // Update session
  useEffect(() => {
    Sentry.setUser({
      id: session?.user?.id ?? "",
    })

    if (window.dataLayer) {
      window.dataLayer.push({
        user_id: session?.user?.id ?? "",
        persona_id: session?.user?.personaId ?? "",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  return (
    <SessionProvider>
      <DialogAuth session={session} />
      {children}
    </SessionProvider>
  )
}
