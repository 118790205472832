import { getCookie, setCookie } from "cookies-next"
import { DialogAuth, useDialogAuth } from "gtp-ui"
import { useSearchParams } from "next/navigation"
import { type Session } from "next-auth"
import { useSession } from "next-auth/react"
import { useCallback, useEffect } from "react"
import { useSnackbar } from "shared-ui"

import { notificationLabel } from "./types"
import { signIn, isFirstSignIn } from "@/authentication/authService"
import { AUTH } from "@/config/client"
import protectedRoutes from "@/utils/protectedRoutes"

type TDialogProvider = {
  session?: Session | null
}

const DialogAuthComponent = ({ session: sessionServer }: TDialogProvider) => {
  const { data: sessionClient } = useSession()
  const { enqueueSnackbar } = useSnackbar()
  const { openDialogAuth, setDialogAuthData } = useDialogAuth()
  const params = useSearchParams()?.get("callbackUrl")
  const isBendahara = params?.includes("bendahara")
  const cookiesNotif = getCookie(isFirstSignIn)

  const handleShowNotifications = () => {
    if (sessionServer?.error && notificationLabel?.[sessionServer?.error]) {
      const errorType = sessionServer?.error
      setCookie(isFirstSignIn, false)
      enqueueSnackbar({
        type: "error",
        message: notificationLabel?.[errorType],
      })
    } else if (sessionServer?.token?.accessToken) {
      setCookie(isFirstSignIn, false)
      enqueueSnackbar({
        message: notificationLabel.SUCCESS_LOGIN,
        type: "success",
      })
    }
  }

  const checkUrlAccess = () => {
    if (isBendahara) {
      setDialogAuthData({
        description:
          "Anda perlu login terlebih dahulu, untuk melanjutkan proses tersebut",
      })
    } else {
      setDialogAuthData({})
    }
    openDialogAuth()
  }

  const checkCallbackUrl = useCallback(() => {
    if (params) {
      const cleanParams = params?.split("?")[0] || ""
      if (protectedRoutes.matcher.includes(cleanParams)) {
        checkUrlAccess()
      } else {
        const replaceLastPath = (inputString: string): string => {
          const urlArray = inputString.split("/")
          urlArray[urlArray.length - 1] = ":path*"
          return urlArray.join("/")
        }
        const protectedRoute = replaceLastPath(params)
        if (protectedRoutes.matcher.includes(protectedRoute)) {
          checkUrlAccess()
        }
      }
    }
  }, [params, openDialogAuth, setDialogAuthData])

  useEffect(() => {
    checkCallbackUrl()
  }, [checkCallbackUrl])

  useEffect(() => {
    if (cookiesNotif?.toString() === "true") {
      handleShowNotifications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DialogAuth
      errorSession={sessionClient?.error}
      baseUrl={AUTH.BASE_URL}
      signIn={signIn}
      shouldRefreshPage={
        sessionServer?.token?.accessToken !==
        sessionServer?.token?.previousAccessToken
      }
    />
  )
}

export default DialogAuthComponent
