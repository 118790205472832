const protectedRoutes = {
  matcher: [
    "/chat",
    "/bendahara",
    "/bendahara/:path*",
    "/cart",
    "/checkout",
    "/checkout/:path*",
    "/notification",
    "/settings/:path*",
    "/onboarding",
    "/orders/:path*",
    "/orders",
    "/negotiation",
    "/negotiation/:path*",
    "/approve-ppk",
    "/approve-ppk/:path*",
    "/bast",
    "/pdf-viewer",
    "/document-pdf-viewer",
    "/mini-competition/:path*",
    "/mini-competition/create/:path*",
    "/form-negotiation",
    "/form-negotiation/:path*",
  ],
}
export default protectedRoutes
